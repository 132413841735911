@import "src/app/core/scss/partial/variables";

@tailwind base;
@tailwind components;
@tailwind utilities;

html, body {
  height: 100%;
  background:$black ;
  margin: 0!important;
  overflow: hidden;
}
body {
  height: 100%;
  background:$black ;
  margin: 0!important;
  scrollbar-width: auto;
  -ms-overflow-style: auto;
}
.mat-drawer-container {
  position: relative;
  z-index: 1;
  height: 100%;
  background-color: transparent!important;
  box-sizing: border-box;
  -webkit-overflow-scrolling: touch;
  display: block;
  overflow: hidden;
}
.mat-drawer {
  width: 100%!important;
  @media (min-width: map-get($breakpoints, md)) {
    width: 100%!important;
    max-width: 350px;
  }
}
.mat-drawer-side {
  box-shadow: none;
  border:none!important;
}
.button_switch{
  width: 100%;
  max-width: 120px;
  height: 38px;
  clip-path: polygon(6% 0, 100% 0, 100% 0, 100% 70%, 94% 100%, 0 100%, 0 100%, 0 30%);
  font-family: 'Hanken Grotesk', sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.button{
  width: 100%;
  max-width: 200px;
  height: 38px;
  clip-path: polygon(6% 0, 100% 0, 100% 0, 100% 70%, 94% 100%, 0 100%, 0 100%, 0 30%);
  font-family: 'Hanken Grotesk', sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: auto 50px auto 0;
}
.button_war{
  width: 100%;
  max-width: 130px;
  height: 30px;
  clip-path: polygon(6% 0, 100% 0, 100% 0, 100% 70%, 94% 100%, 0 100%, 0 100%, 0 30%);
  font-family: 'Hanken Grotesk', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0 auto;
}
.button_ships{
  width: 100%;
  max-width: 200px;
  height: 38px;
  clip-path: polygon(6% 0, 100% 0, 100% 0, 100% 70%, 94% 100%, 0 100%, 0 100%, 0 0);
  font-family: 'Hanken Grotesk', sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: -1px;
  margin-left: -1px;
  padding-left: 25px;
  text-align: start!important;
}
.button_fleet_type{
  width: 100%;
  max-width: 150px;
  height: 38px;
  font-family: 'Hanken Grotesk', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  margin-top: -1px;
  margin-right: -1px;
  line-height: normal;
}
.button_fleet_battle{
  width: 100%;
  max-width: 180px;
  height: 33px;
  font-family: 'Hanken Grotesk', sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  clip-path: polygon(0 0, 100% 0, 100% 63%, 91% 100%, 0 100%, 0% 50%);
  padding: 0 10px;
}
.mat-expansion-panel-body {
  padding: 0 24px 0 0!important;
}
.mat-expansion-panel-header {
  padding: 0 24px 0 0 !important;
  height: 30px!important;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 1px $light-gray;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $purple;
  border-radius: 10px;
  cursor: pointer;
}
::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}
::-webkit-scrollbar:horizontal{
  height: 7px;
  -webkit-appearance: none;
}
::-webkit-scrollbar-thumb:horizontal{
  background: $purple;
  border-radius: 10px;
}
.mdc-linear-progress__buffer {
  border-radius: 10px!important;
  height: 10px!important;
}
.mat-mdc-progress-bar {
  border-radius: 10px!important;
  height: 10px!important;
}
.mdc-linear-progress__primary-bar {
  transform: scaleX(0);
  border-radius: 10px!important;
  height: 10px!important;
}
.mdc-linear-progress__bar-inner {
  border-radius: 10px!important;
  height: 10px!important;
}
.mat-mdc-progress-bar {
  --mdc-linear-progress-active-indicator-height: 10px!important;
  --mdc-linear-progress-track-height: 10px!important;
  --mdc-linear-progress-track-shape: 0;
}
.mat-mdc-snack-bar-container {
  --mat-mdc-snack-bar-button-color: #ffffff;
  --mdc-snackbar-supporting-text-color: #ffffff;

  &.app-notification-error {
    --mdc-snackbar-container-color: #702018;
  }

  &.app-notification-success {
    --mdc-snackbar-container-color: #9D57F6;
  }
}

.mat-mdc-dialog-container .mdc-dialog__surface {
  border-radius: var(--mdc-dialog-container-shape, 4px);
  background: linear-gradient(105deg, #212121 45.62%, #333 105.31%)!important;
}
.amount_ships{
  font-family:'Hanken Grotesk',sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.title_small{
  font-family:'Hanken Grotesk',sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.subtitle{
  font-family:'Hanken Grotesk',sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.subtitle_time{
  font-family:'Space Grotesk',sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.desc_planet{
  font-family: 'Hanken Grotesk', sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: $purple;
}
.desc_planet_header{
  font-family: 'Hanken Grotesk', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: $purple;
}
.desc-s{
  font-family:'Space Grotesk',sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.desc-h{
  font-family:'Hanken Grotesk',sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.desc-battle{
  font-family: 'Hanken Grotesk', sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
